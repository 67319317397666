import styled from '@emotion/styled'
import React, { useState } from 'react'
import { MdCheck as CheckIcon } from 'react-icons/md'
import { Button } from 'shop/components'
import Spinner from 'shop/components/Loader/Spinner'
import { useCheckoutV2, useConsumerCart } from 'shop/hooks'
import { StyledHTMLElement } from 'shop/theme/types'
import { AvailableReward, RewardDetail, SummaryReward } from 'shop/types/cart'
import { Label, LineBreak } from '../commonStyles'
import {
  trackAddDiscountCodeConsumerCart,
  trackDeniedDiscountCodeConsumerCart
} from 'shop/components/Checkout/tracking/helpers'

interface RewardsProps {
  rewards: AvailableReward[]
  appliedReward: SummaryReward | null
}

interface RewardItemProps {
  details: RewardDetail
  id: string
  isLast: boolean
  handleApplyReward: (id: string) => void
  isApplied: boolean
  loadingRewardId: string
}

const RewardItem = ({
  details,
  id,
  isLast,
  handleApplyReward,
  isApplied,
  loadingRewardId
}: RewardItemProps) => (
  <React.Fragment key={id}>
    <RewardItemContainer isLast={isLast}>
      <RewardDetails>
        <RewardTitle>{details.name}</RewardTitle>
        <RewardDesc>{details.description}</RewardDesc>
      </RewardDetails>
      <Button
        size='md'
        width='94px'
        onClick={() => handleApplyReward(id)}
        disabled={isApplied || !!loadingRewardId}
      >
        {loadingRewardId === id ? (
          <Spinner />
        ) : isApplied ? (
          <CheckIcon />
        ) : (
          'Redeem'
        )}
      </Button>
    </RewardItemContainer>
  </React.Fragment>
)

const Rewards = ({ rewards, appliedReward }: RewardsProps) => {
  const { applyRewardConsumerCart } = useConsumerCart()
  const { rewardsRef } = useCheckoutV2()
  const [loadingRewardId, setLoadingRewardId] = useState('')

  const handleApplyReward = (id: string, code: string) => {
    setLoadingRewardId(id)
    applyRewardConsumerCart({
      variables: { rewardId: id }
    })
      .then(() => {
        trackAddDiscountCodeConsumerCart(code)
      })
      .catch(() => {
        trackDeniedDiscountCodeConsumerCart(code)
      })
      .finally(() => {
        setLoadingRewardId('')
      })
  }

  return (
    <Container ref={rewardsRef} data-testid='rewards-container'>
      <LineBreak height={'2px'} />
      <LabelContainer>
        <Label withEllipsis={true} fontWeight={600}>
          Rewards
        </Label>
      </LabelContainer>
      <RewardItemList>
        {rewards.map(({ details, id }, index) => (
          <RewardItem
            key={id}
            details={details}
            id={id}
            isLast={index === rewards.length - 1}
            handleApplyReward={() => handleApplyReward(id, details.name)}
            isApplied={id === appliedReward?.id}
            loadingRewardId={loadingRewardId}
          />
        ))}
      </RewardItemList>
    </Container>
  )
}

export default Rewards

const Container = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

const LabelContainer = styled.div<StyledHTMLElement>(() => ({
  margin: '24px 0 8px',
  '> p': {
    margin: '0'
  }
}))

const RewardItemList = styled.ul<StyledHTMLElement>({ margin: 0 })

const RewardItemContainer = styled.li<StyledHTMLElement & { isLast: boolean }>(
  ({ isLast }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: isLast ? '16px 0 24px' : '16px 0',

    '& button': {
      backgroundColor: '#fff',
      border: '1px solid #000',
      color: '#000',

      '& svg': {
        margin: 0
      }
    }
  })
)

const RewardDetails = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  flexDirection: 'column',
  '> p': {
    margin: '0'
  }
}))

const RewardTitle = styled.p<StyledHTMLElement>(() => ({
  display: 'flex',
  fontSize: '16px',
  fontWeight: 500
}))

const RewardDesc = styled.p<StyledHTMLElement>(() => ({
  display: 'flex',
  fontSize: '14px'
}))
