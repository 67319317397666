import { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import AlertMessage from '../components/Cart/AlertMessage'
import Spinner from 'shop/components/Loader/Spinner'
import styled from '@emotion/styled'
import {
  Button,
  Heading,
  CustomerOptInV2,
  CustomerDetailsFormV2,
  RecipientDetailsV2,
  ShippingDetailsV2,
  OrderNotesV2,
  CustomFieldV2,
  NavBarV2
} from 'shop/components'
import { CheckoutActions } from 'shop/components/Checkout/FormElements'
import {
  UpdateDateCartRecipientDetailsV2,
  getPayNextStep,
  getTipValues
} from 'shop/components/Checkout/Network'
import {
  getPrefilledAddressV2,
  getFormDefaultValueV2,
  returnToShop,
  hasCustomField,
  updateContactNumV2,
  returnStoreBaseAddress,
  getFulfillmentFlags,
  defaultCheckoutErrorMessage,
  getIsAddressFormOpen,
  findAndSetCartErrors,
  findAndSetDeliveryErrors,
  getCheckoutCtaValues,
  getApplicableRewards
} from 'shop/components/Checkout/utils'
import { strippedContactNumPrefix } from 'shop/components/Inputs/utils'
import {
  CheckoutFormV2,
  FORM_DEFAULTS_V2,
  ADDRESS_DEFAULTS_V2,
  FormFieldsV2
} from 'shop/components/Checkout/types'
import {
  useShop,
  useConsumerCart,
  useAppContent,
  useModal,
  useCheckoutV2,
  useMediaQueries,
  useIntersectionObserver
} from 'shop/hooks'
import { useForm } from 'react-hook-form'
import { UseFormMethods } from 'react-hook-form'
import isEmpty from 'lodash/isEmpty'
import { usePageViewTracker } from 'tracker'
import { useHistory } from 'react-router-dom'
import TippingV2 from 'shop/components/Tipping/TippingV2'
import { useSessionStorage } from 'shop/hooks/useSessionStorage'
import { keysHaveValues } from 'shop/utils/common'
import { CartV2, FulfillmentDetails } from 'shop/components/CartV2'
import { Theme } from 'styled-system'
import { StyledHTMLElement } from 'shop/theme/types'
import {
  CartMutationResponse,
  ConsumerCart,
  DomesticAddress
} from 'shop/types/cart'
import {
  trackConsumerCart,
  trackDeniedDiscountCodeConsumerCart,
  trackMovActioned,
  trackRemoveDiscountCodeConsumerCart
} from 'shop/components/Checkout/tracking/helpers'
import DiscountErrorModal from 'shop/components/ErrorModals/DiscountErrorModal'
import { FulfillmentType } from 'shop/types'
import ErrorModal from 'shop/components/Modal/ErrorModal'
import GiftWrappingV2 from 'shop/components/CartV2/GiftWrappingV2/GiftWrappingV2'

const Checkout = () => {
  const {
    useShopClient,
    customerDetails,
    currentStore,
    setCurrentStore,
    allStores
  } = useShop()
  const { isMobile } = useMediaQueries()
  const {
    cart: consumerCart,
    updateConsumerCart,
    updateFulfillmentConsumerCart,
    setCart,
    removeDiscountConsumerCart,
    cartLoading,
    errors: consumerCartErrors,
    warnings: consumerCartWarnings,
    validateCart
  } = useConsumerCart()
  const {
    timeSlotValidation,
    discountWarningValidations,
    giftWrapped,
    giftWrapMessage,
    setGiftWrapped,
    setGiftWrapMessage,
    updateCartGiftWrapping,
    rewardsRef
  } = useCheckoutV2()
  const client = useShopClient()
  const isLoggedIn = localStorage.getItem('customerId') !== null
  const [isDeliveryLoading, setIsDeliveryLoading] = useState(false)
  const [showDiscountWarning, setShowDiscountWarning] = useState(false)
  const [shouldValidateCart, setShouldValidateCart] = useState(false)
  const history = useHistory()

  const {
    getSessionStorageItem: getCheckoutFormSessionStorage,
    setSessionStorageItem: setCheckoutFormSessionStorage
  } = useSessionStorage<CheckoutFormV2>('temp-checkout-form-values')

  /** Pass cartLoading value to handleValidateCart to ensure correct useState value is sent */
  const handleValidateCart = useCallback(() => {
    validateCart(cartLoading)
  }, [cartLoading, validateCart])

  useEffect(() => {
    if (shouldValidateCart) {
      handleValidateCart()
      setShouldValidateCart(false)
    }
  }, [shouldValidateCart, handleValidateCart])

  useEffect(() => {
    const SECOND = 1000
    let pollCartVerificationInterval: NodeJS.Timeout
    let checkStartPollInterval: NodeJS.Timeout

    const currentSeconds = new Date().getSeconds()
    const timeUntilNextMinute = (60 - currentSeconds + 1) * SECOND

    const startPolling = () => {
      // Schedule the first validation at the start of the next minute
      pollCartVerificationInterval = setTimeout(() => {
        setShouldValidateCart(true)
        // Schedule subsequent polling every 30 seconds
        checkStartPollInterval = setInterval(() => {
          setShouldValidateCart(true)
        }, 30 * SECOND)
      }, timeUntilNextMinute)
    }

    // Start validateCart polling
    startPolling()

    return () => {
      if (pollCartVerificationInterval) {
        clearTimeout(pollCartVerificationInterval)
      }
      if (checkStartPollInterval) {
        clearInterval(checkStartPollInterval)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Values from session storage are used to prefill the form if no cart loaded
  const tempSavedCheckoutValues = getCheckoutFormSessionStorage()

  const defaultFormValues = tempSavedCheckoutValues
    ? tempSavedCheckoutValues
    : FORM_DEFAULTS_V2

  const checkoutForm = useForm({
    mode: 'onBlur',
    defaultValues: defaultFormValues
  })

  // Track page view
  usePageViewTracker()

  const {
    getValues,
    register,
    setError: setFormError,
    clearErrors,
    errors,
    watch,
    reset
  }: UseFormMethods<FormFieldsV2> = checkoutForm
  const { merchantName } = useAppContent()
  const { merchant } = useShop()
  const { isModalOpen, loginModal, timeSlotModalV2 } = useModal()

  const [isPaying, setIsPaying] = useState<boolean>(false)
  const [paymentError, setPaymentError] = useState<Error | null>(null)
  const [tippingValues, setTippingValues] = useState<{
    [key: number]: number
  } | null>(null)
  const [isTippingOpen, setIsTippingOpen] = useState<boolean>(false)
  const [paymentMode, setPaymentMode] = useState<'single' | 'split'>('single')
  const [deliveryAddress, setDeliveryAddress] = useState<DomesticAddress>(
    getPrefilledAddressV2(
      consumerCart,
      tempSavedCheckoutValues,
      ADDRESS_DEFAULTS_V2
    )
  )
  const [hasSeenRewards, setHasSeenRewards] = useState<boolean>(false)

  const fulfillmentType = consumerCart?.fulfillment?.type

  const [isDeliveryAddressFormOpen, setIsDeliveryAddressFormOpen] =
    useState<boolean>(false)

  const prevAddressRef = useRef<DomesticAddress | undefined>()
  const orderNotesEnabled = watch('orderNotesEnabled')
  const dropoffNotes = watch('dropoffNotes')

  // Check for subtotal discount - MOV
  const hasSubtotalDiscount = useMemo(() => {
    // FIXME: use consumerCart?.summary.subtotal.reduction instead
    const hasSubtotalReduction =
      consumerCart?.summary.subtotal.discounted !==
      consumerCart?.summary.subtotal.base
    const hasDiscount = !!consumerCart?.summary.discount
    return !!(hasDiscount && hasSubtotalReduction)
  }, [consumerCart?.summary.subtotal.discounted])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (fulfillmentType) {
      const initialIsDeliveryAddressFormOpen = getIsAddressFormOpen(
        fulfillmentType,
        deliveryAddress
      )
      setIsDeliveryAddressFormOpen(initialIsDeliveryAddressFormOpen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentType])

  /** Temp: Set currentstore from consumerCart as we no longer set old cart here
   * Should be moved to useShop eventually using consumerCart as dependency
   */
  useEffect(() => {
    const { store } = consumerCart || {}
    const { slug: storeSlug } = store || {}
    if (storeSlug && allStores.length) {
      if (storeSlug !== currentStore?.slug) {
        const matchedStore = allStores.find((store) => store.slug === storeSlug)
        if (matchedStore) {
          setCurrentStore(matchedStore)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStores, consumerCart])

  /** Hydrates form with saved cart/session values / check validity of delivery address */
  const initializeForm = (
    cart: ConsumerCart,
    fulfillmentType: FulfillmentType
  ) => {
    const isForSomeoneElse =
      !!cart.recipientDetails ||
      (!!tempSavedCheckoutValues?.recipientDetails &&
        keysHaveValues(
          Object.keys(FORM_DEFAULTS_V2['recipientDetails']),
          tempSavedCheckoutValues.recipientDetails
        )) ||
      FORM_DEFAULTS_V2['forSomeoneElse']

    const formValuesToSet = {
      ...FORM_DEFAULTS_V2,
      deliveryAddress: deliveryAddress,
      customerDetails: getFormDefaultValueV2('customerDetails', [
        cart,
        tempSavedCheckoutValues
      ]),
      recipientDetails: getFormDefaultValueV2('recipientDetails', [
        cart,
        tempSavedCheckoutValues
      ]),
      forSomeoneElse: isForSomeoneElse,
      orderNotes: getFormDefaultValueV2('orderNotes', [
        cart,
        tempSavedCheckoutValues
      ]),
      orderNotesEnabled: getFormDefaultValueV2('orderNotes', [
        cart,
        tempSavedCheckoutValues
      ]),
      dropoffNotes: getFormDefaultValueV2('dropoffNotes', [
        cart,
        tempSavedCheckoutValues
      ]),
      marketingOptIn:
        tempSavedCheckoutValues?.marketingOptIn ??
        (cart?.marketingOptIn || true),
      customFieldValue:
        cart.customField?.value ||
        tempSavedCheckoutValues?.customFieldValue ||
        FORM_DEFAULTS_V2.customFieldValue
    }

    let { customerDetails, recipientDetails } = formValuesToSet

    customerDetails = {
      ...customerDetails,
      ...updateContactNumV2(
        customerDetails?.contactNumber,
        customerDetails?.contactNumPrefix
      )
    }
    if (recipientDetails) {
      recipientDetails = {
        ...recipientDetails,
        ...updateContactNumV2(
          recipientDetails?.contactNumber,
          recipientDetails?.contactNumPrefix
        )
      }
    }

    reset({ ...formValuesToSet, recipientDetails, customerDetails })

    setDeliveryAddress(deliveryAddress)
    prevAddressRef.current = { ...deliveryAddress }

    // On init, if it is a delivery fulfillment & delivery address form is not
    // open (has all required values to attempt BE validation)
    // call updateDeliveryAddress to validate prefilled address
    const initialIsDeliveryAddressFormOpen = getIsAddressFormOpen(
      fulfillmentType,
      deliveryAddress
    )

    if (isDelivery && !initialIsDeliveryAddressFormOpen) {
      updateDeliveryAddress(deliveryAddress)
    }
  }

  /** Initialise component */
  useEffect(() => {
    let isMounted = true

    const startLoad = async () => {
      if (!merchantName || !fulfillmentType) return
      if (!isMounted) return

      // If user tries to navigate to checkout page with no order items reroute user
      if (!consumerCart?.orderItems?.length) {
        returnToShop(currentStore, history)
        return
      }

      const storeName = consumerCart.store.name

      trackConsumerCart(consumerCart, merchantName, storeName)
      initializeForm(consumerCart, fulfillmentType)
    }

    startLoad()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantName, fulfillmentType])

  /** Get tip values first time cart is loaded */
  useEffect(() => {
    let isMounted = true
    if (!consumerCart?.id) return

    getTipValues(client, consumerCart.id).then((res) => {
      if (isMounted && res.data.tipsValues) {
        setTippingValues(res.data.tipsValues.tips)
      }
    })

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerCart?.id])

  /** Handles consumer delivery address & cart validations. Sets appropriate UI error messages */
  useEffect(() => {
    const hasValidationInfo =
      !!consumerCartErrors.length || !!consumerCartWarnings.length

    if (!hasValidationInfo) {
      clearErrors('address_api')
      clearErrors('checkout_cart')
      return
    }

    // Find and handle cart error code from cart errors
    findAndSetCartErrors(
      consumerCartErrors,
      consumerCartWarnings,
      hasSubtotalDiscount,
      setFormError,
      clearErrors
    )
    // Find and handle delivery error code from cart errors
    const deliveryErrorCode = findAndSetDeliveryErrors(
      consumerCartErrors,
      consumerCartWarnings,
      setFormError,
      clearErrors
    )
    if (deliveryErrorCode) setIsDeliveryAddressFormOpen(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerCartWarnings, consumerCartErrors])

  const showOptIn: boolean = useMemo(() => {
    // guest checkout
    if (!isLoggedIn) return true
    // account and not yet opted in
    if (!customerDetails?.marketingOptIn) return true
    return false
  }, [isLoggedIn, customerDetails?.marketingOptIn])

  const updateDeliveryAddress = async (address: DomesticAddress) => {
    setIsDeliveryLoading(true)
    const deliveryAddressWithCountry = {
      ...address,
      country: 'United Kingdom'
    }
    return updateFulfillmentConsumerCart({
      deliveryAddress: deliveryAddressWithCountry
    })
      .then((cart: ConsumerCart | null) => {
        if (cart) {
          setCart(cart)
        }
      })
      .catch(() => {
        setFormError('address_api', {
          type: 'manual',
          message: defaultCheckoutErrorMessage
        })
        setIsDeliveryAddressFormOpen(true)
      })
      .finally(() => {
        setIsDeliveryLoading(false)
      })
  }

  /** Update fulfillment type via discount error modal */
  const updateFulfillmentType = (fulfillmentType: FulfillmentType) => {
    setIsDeliveryLoading(true)
    return updateFulfillmentConsumerCart({
      fulfillmentType: fulfillmentType
    })
      .then((cart: ConsumerCart | null) => {
        if (cart) {
          setCart(cart)
        }
      })
      .catch(() => {
        setFormError('fulfillment_api', {
          type: 'manual',
          message: defaultCheckoutErrorMessage
        })
        setIsDeliveryAddressFormOpen(true)
      })
  }

  // If rewards have come into viewport, set hasSeenRewards to true
  const handleRewardsIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setHasSeenRewards(true)
      }
    })
  }

  // Create intersectionObs to check if rewards have been scrolled 50% up the viewport
  const rewardsObserver = useIntersectionObserver(
    handleRewardsIntersection,
    rewardsRef.current,
    {
      root: null,
      threshold: 0,
      rootMargin: '0px 0px -50% 0px'
    }
  )

  useEffect(() => {
    // Stop checking if rewards are in viewport once user has seen them or clicked CTA
    if (hasSeenRewards && rewardsObserver) {
      rewardsObserver.disconnect()
    }
  }, [hasSeenRewards, rewardsObserver])

  if (!consumerCart?.summary || !fulfillmentType) return <></>

  const {
    orderItems,
    fulfillment,
    summary,
    store,
    loyaltyCards,
    orderNotes,
    deliveryAddress: cartDeliveryAddress,
    additionalItems
  } = consumerCart
  const { slug: storeSlug } = store

  const { isOrderAtTable, isDelivery, isPickup } =
    getFulfillmentFlags(fulfillmentType)

  const { checkoutPayCtaActive, checkoutCtaText } = getCheckoutCtaValues(
    loyaltyCards,
    isMobile,
    hasSeenRewards
  )

  const {
    gift_wrap_enabled,
    gift_wrap_placeholder,
    gift_wrap_price,
    gift_wrap_text
  } = merchant
  const showGiftWrap: boolean = !!gift_wrap_enabled && !!gift_wrap_price

  const storeAddress = returnStoreBaseAddress(store.address)

  const showCustomField = () => {
    if (!merchant || !fulfillmentType) return false

    const {
      order_custom_field,
      order_custom_field_name,
      order_custom_field_apply_for
    } = merchant
    const type = fulfillmentType.toLowerCase()
    return hasCustomField(
      { fulfillment_type: type, id: consumerCart.id },
      {
        enabled: order_custom_field,
        name: order_custom_field_name,
        fulfillment_types: order_custom_field_apply_for
      }
    )
  }

  /** Updates cart when user clicks pay & navigates to payment page */
  const checkout = (tipAmount?: number) => {
    setIsTippingOpen(false)

    const {
      customerDetails: {
        contactNumPrefix,
        contactNumber,
        ...otherCustomerDetails
      },
      recipientDetails,
      orderNotes,
      dropoffNotes,
      marketingOptIn,
      customFieldValue
    } = checkoutForm.getValues()

    setIsPaying(true)

    const customerDetails = {
      ...otherCustomerDetails,
      contactNumber: `${strippedContactNumPrefix(
        contactNumPrefix
      )}${contactNumber}`
    }

    let amendedRecipientDetails: UpdateDateCartRecipientDetailsV2 =
      recipientDetails
    if (recipientDetails?.firstName) {
      const {
        contactNumPrefix: rec_prefix,
        contactNumber: rec_num,
        ...otherRecipientDetails
      } = recipientDetails

      amendedRecipientDetails = {
        ...otherRecipientDetails,
        contactNumber: `${strippedContactNumPrefix(rec_prefix)}${rec_num}`
      }
    }

    // Ensure customer_details.email is in lowercase
    if (customerDetails?.email) {
      customerDetails.email = customerDetails.email.toLowerCase()
    }

    return updateConsumerCart(
      {
        customFieldValue,
        customerDetails,
        orderNotes,
        recipientDetails: amendedRecipientDetails || recipientDetails,
        dropoffNotes,
        marketingOptIn,
        tipValue: tipAmount
      },
      { skipLoading: true }
    )
      .then((cartResponse: CartMutationResponse | null) => {
        const { cart, warnings, errors } = cartResponse || {}

        // Validation handling
        if (errors?.length || warnings?.length) {
          // Trigger discount warnings
          if (discountWarningValidations.length) {
            setShowDiscountWarning(true)
            setIsPaying(false)
            return
          }

          // Block users with generic error if there are errors or warnings that we are not handling
          setPaymentError(
            new Error(
              'Unable to process payments at this time. Please try again later.'
            )
          )
          setIsPaying(false)
          return
        }

        if (cart) {
          setCart(cart)
          return getPayNextStep(client, consumerCart.id)
        }
        throw new Error('Update cart failed')
      })
      .then(async (result) => {
        if (result) {
          if (paymentMode === 'split') {
            return (window.location.href = '/order/split')
          }

          if (result.data.payForCart.nextStep === 'skip') {
            const transactionId = result.data.payForCart.transactionId
            return history.push(`/track/${transactionId}`)
          }

          return history.push('/pay')
        }
      })
      .catch((error) => {
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          switch (error.graphQLErrors[0].code) {
            case 'STRIPE_AMOUNT_TOO_SMALL':
              return setPaymentError(
                new Error('Please ensure your total is above £0.30')
              )
            default:
              return setPaymentError(
                new Error(
                  'Unable to process payments at this time. Please try again later.'
                )
              )
          }
        }

        setPaymentError(
          new Error(
            'Unable to process payments at this time. Please try again later.'
          )
        )
        return setIsPaying(false)
      })
  }

  const handleCloseTippingModal = () => setIsTippingOpen(false)

  const tippingEnabled = isDelivery
    ? currentStore?.settings.delivery_tipping_enabled
    : isPickup
      ? currentStore?.settings.pickup_tipping_enabled
      : isOrderAtTable
        ? currentStore?.settings.oat_tipping_enabled
        : false

  // Temporarily disable Split the Bill until we fully support it again.
  const showSplitTheBill = false
  // isOrderAtTable && totalNumber !== 0 && merchant?.split_bill_enabled

  const showTippingModal = isTippingOpen && tippingEnabled && tippingValues

  const setCheckoutValuesOnBlur = () => {
    setCheckoutFormSessionStorage(getValues())
  }

  const handleBlur = () => {
    setDeliveryAddress(getValues().deliveryAddress)
  }

  const handleEnterKeyPress = (e: React.KeyboardEvent) => {
    const focusedInput: HTMLInputElement | null =
      document.querySelector('input:focus')
    if (e.key === 'Enter' && focusedInput) {
      focusedInput.blur()
      e.preventDefault()
    }
  }

  const scrollToRewards = () => {
    if (rewardsRef.current) {
      const topPosition =
        rewardsRef.current.getBoundingClientRect().top + window.scrollY - 50
      window.scrollTo({ top: topPosition, behavior: 'smooth' })
    }
  }

  /** Scrolls to rewards area if mobile user & has rewards, else normal payment flow */
  const handleSubmitForm = checkoutForm.handleSubmit(() => {
    if (checkoutPayCtaActive) {
      if (tippingEnabled) {
        setIsTippingOpen(true)
      } else {
        checkout()
      }
    } else {
      scrollToRewards()
    }
  })

  const handleSplitTheBill = () => {
    setPaymentMode('split')
    handleSubmitForm()
  }

  const fulfillmentDetailsOnChangeClick = () =>
    setIsDeliveryAddressFormOpen(true)

  const handleRemoveDiscountViaModal = (submitForm: boolean = false) => {
    const discountCode = summary.discount?.code as string
    removeDiscountConsumerCart()
      .then(() => {
        trackRemoveDiscountCodeConsumerCart(discountCode)
        if (submitForm) {
          setShowDiscountWarning(false)
          handleSubmitForm()
        }
      })
      .catch(() => {
        trackDeniedDiscountCodeConsumerCart(discountCode)
      })
  }

  return (
    <>
      {isModalOpen('login') && loginModal({ merchantId: merchant.id })}
      {isModalOpen('timeslotV2') && timeSlotModalV2(timeSlotValidation)}
      {!!discountWarningValidations.length && showDiscountWarning && (
        <DiscountErrorModal
          errorCode={discountWarningValidations[0].message}
          values={
            discountWarningValidations[0].message ===
            'DISCOUNT_MINIMUM_VALUE_NOT_MET'
              ? {
                  minimumOrderValue: discountWarningValidations[0].minimumValue
                }
              : {}
          }
          origin={'checkout'}
          checkoutRemoveDiscount={() => handleRemoveDiscountViaModal(true)}
          updateFulfillmentType={updateFulfillmentType}
          storeSlug={storeSlug}
        />
      )}
      {!!errors?.['checkout_cart'] && (
        <ErrorModal
          errorCode='MOV_ERROR'
          errorMessages={[errors['checkout_cart'].message]}
          errorTitle='Minimum value not met'
          primaryButton={{
            onClick: () => {
              trackMovActioned()
              returnToShop(currentStore, history)
            },
            message: 'Add more items'
          }}
          secondaryButton={
            hasSubtotalDiscount
              ? {
                  onClick: () => handleRemoveDiscountViaModal(),
                  message: 'Remove discount code'
                }
              : undefined
          }
        />
      )}
      {showTippingModal && (
        <TippingV2
          tippingValues={tippingValues}
          handleCloseModal={handleCloseTippingModal}
          handleSubmitTip={checkout}
        />
      )}
      <Container>
        <NavBarV2 page='checkout' storeSlug={store.slug} />
        <ContentWrapper>
          <form
            onBlur={setCheckoutValuesOnBlur}
            onSubmit={handleSubmitForm}
            onKeyPress={handleEnterKeyPress}
          >
            {paymentError && (
              <AlertMessage type='error' heading={paymentError.message} />
            )}
            <FormSection>
              <Heading as='h2' margin='0 0 32px'>
                Personal Details
              </Heading>
              <CustomerDetailsFormV2
                formHandle={checkoutForm}
                onBlur={handleBlur}
              />
            </FormSection>
            {showGiftWrap && (
              <FormSection>
                <GiftWrappingV2
                  giftWrapped={giftWrapped}
                  giftWrapMessage={giftWrapMessage}
                  setGiftWrapped={setGiftWrapped}
                  setGiftWrapMessage={setGiftWrapMessage}
                  updateCartGiftWrapping={updateCartGiftWrapping}
                  giftWrapPrice={gift_wrap_price}
                  giftWrapPlaceholder={gift_wrap_placeholder}
                  giftWrapText={gift_wrap_text}
                />
              </FormSection>
            )}
            {isDelivery && (
              <>
                <FormSection margin='24px 0 32px'>
                  <RecipientDetailsV2 formHandle={checkoutForm} />
                </FormSection>
              </>
            )}
            {showCustomField() && (
              <FormSection>
                <CustomFieldV2
                  fieldName={merchant?.order_custom_field_name}
                  required={merchant?.order_custom_field_mandatory}
                  placeholder={merchant?.order_custom_field_placeholder || ''}
                  formHandle={checkoutForm}
                  error={errors.customFieldValue}
                />
              </FormSection>
            )}
            <LineBreak />
            <FormSection>
              <FulfillmentDetailsContainer
                hasDropoffNotes={
                  isDelivery && !isDeliveryAddressFormOpen && !!dropoffNotes
                }
              >
                <FulfillmentDetails
                  fulfillment={fulfillment}
                  storeName={store.name}
                  merchantName={merchantName}
                  deliveryAddress={cartDeliveryAddress}
                  storeAddress={storeAddress}
                  dropoffNotes={dropoffNotes}
                  {...(isDelivery && {
                    onChangeButtonClick: fulfillmentDetailsOnChangeClick
                  })}
                  hideAddressSection={isDeliveryAddressFormOpen}
                />
              </FulfillmentDetailsContainer>
              <LineBreak />
              {isDelivery && isDeliveryAddressFormOpen && (
                <>
                  <ShippingDetailsV2
                    onBlur={handleBlur}
                    formHandle={checkoutForm}
                    displayedAddress={deliveryAddress}
                    deliveryNotePlaceholder={
                      merchant?.delivery_note_placeholder
                    }
                    setIsDeliveryLoading={setIsDeliveryLoading}
                    updateCart={updateDeliveryAddress}
                    prevAddress={prevAddressRef}
                  />
                  <LineBreak />
                </>
              )}
            </FormSection>
            {!isOrderAtTable && (
              <FormSection>
                <OrderNotesV2
                  formHandle={checkoutForm}
                  enabled={orderNotesEnabled}
                  placeholder={merchant?.order_note_placeholder}
                  error={errors.orderNotes}
                />
              </FormSection>
            )}
            <FormSection>
              <CustomerOptInV2 {...{ register, isVisible: showOptIn }} />
            </FormSection>
            <CheckoutActions>
              {showSplitTheBill && (
                <Button
                  type='submit'
                  disabled={!isEmpty(errors) || isPaying}
                  name='pay_via'
                  value='split'
                  variant='secondary'
                  onClick={handleSplitTheBill}
                  data-testid='split-the-bill-button'
                >
                  {isPaying && paymentMode === 'split' ? (
                    <Spinner />
                  ) : (
                    'Split the bill'
                  )}
                </Button>
              )}
              <Button
                type='submit'
                disabled={!isEmpty(errors) || isPaying || isDeliveryLoading}
                name='pay_via'
                value='standard'
              >
                {isPaying && paymentMode === 'single' ? (
                  <Spinner />
                ) : (
                  checkoutCtaText
                )}
              </Button>
            </CheckoutActions>
          </form>
          <div>
            <CartV2
              type={'OrderSummary'}
              orderItems={orderItems}
              summary={summary}
              orderNotes={orderNotes}
              loyaltyCards={loyaltyCards}
              fulfillmentType={fulfillmentType}
              additionalItems={additionalItems}
              isEditable={true}
              isCartLoading={cartLoading}
            />
          </div>
        </ContentWrapper>
      </Container>
    </>
  )
}

const FormSection = styled.div<
  StyledHTMLElement & { margin?: string },
  Required<Theme>
>(({ theme, margin }) => ({
  margin: margin || '0 0 24px 0',
  padding: '0 16px',
  [theme.mediaQueries.viewport6]: {
    padding: '0'
  },

  '>h2': {
    fontSize: '20px',
    [theme.mediaQueries.viewport6]: {
      fontWeight: 'bold',
      fontSize: '24px'
    }
  }
}))

const Container = styled.div(() => ({
  minHeight: '100vh',
  backgroundColor: '#F5F5F5',
  position: 'relative'
}))

const LineBreak = styled.hr<StyledHTMLElement>(() => ({
  backgroundColor: '#EFEFF0',
  border: '0',
  height: '1px',
  margin: 0
}))

const ContentWrapper = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    minHeight: 'min-content',
    position: 'relative',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1470px',
    height: '100%',
    display: 'grid',
    gap: '12px',
    paddingBottom: '90px',
    flexDirection: 'column-reverse',
    [theme.mediaQueries.viewport4]: {
      padding: '40px',
      gap: '24px'
    },
    [theme.mediaQueries.viewport8]: {
      gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
      padding: '40px 75px 90px',
      gap: '40px',
      justifyContent: 'center'
    },
    '& > form': {
      backgroundColor: 'white',
      height: 'min-content',
      padding: '24px 4px',
      [theme.mediaQueries.viewport4]: {
        borderRadius: '12px',
        padding: '32px 32px 24px'
      },
      [theme.mediaQueries.viewport8]: {
        position: 'sticky',
        top: '104px'
      }
    },
    '& > div': {
      height: '100%',
      backgroundColor: 'white',
      padding: '24px 16px',
      minWidth: 0,
      [theme.mediaQueries.viewport4]: {
        borderRadius: '12px',
        padding: '32px 32px 12px'
      },
      [theme.mediaQueries.viewport8]: {
        position: 'sticky',
        top: '104px',
        height: 'fit-content'
      }
    }
  })
)

const FulfillmentDetailsContainer = styled.div<
  StyledHTMLElement & { hasDropoffNotes: boolean }
>(({ hasDropoffNotes }) => ({
  margin: hasDropoffNotes ? '28px 0' : '28px 0 0'
}))

export default Checkout
