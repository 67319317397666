import { RouteComponentProps } from 'react-router-dom'
import { AppContentLoader, LoaderSwitcher } from 'router/loaders'
import { CheckoutV2 } from '../../shop/pages'
import { CheckoutContextV2Provider } from 'shop/hooks'
import { ConsumerCartLoader } from 'router/loaders/ConsumerCartLoader'

export const CheckoutWrapper = (props: RouteComponentProps) => {
  return (
    <AppContentLoader>
      {(isParentLoaded) => (
        <ConsumerCartLoader isParentLoaded={isParentLoaded}>
          {(isParentLoaded) => (
            <CheckoutContextV2Provider>
              <LoaderSwitcher isParentLoaded={isParentLoaded}>
                <CheckoutV2 />
              </LoaderSwitcher>
            </CheckoutContextV2Provider>
          )}
        </ConsumerCartLoader>
      )}
    </AppContentLoader>
  )
}
