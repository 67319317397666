import { Cart } from 'shop/components'
import { FulfillmentType } from 'shop/types'
import { ConsumerCart } from 'shop/types/cart'
import {
  TrackableEvent,
  trackAction,
  trackUserActionsFBPixel,
  trackUserActionsGA4
} from 'tracker'
import {
  createEcommEventDataFromCart,
  createEcommEventDataFromConsumerCart,
  merchantGA4EcommTrackAddShippingInfo,
  merchantGA4EcommTrackAddShippingInfoConsumerCart,
  merchantGA4EcommTrackBeginCheckout,
  slerpGA4EcommTrackAddShippingInfo,
  slerpGA4EcommTrackAddShippingInfoConsumerCart,
  slerpGA4EcommTrackBeginCheckout
} from 'tracker/GA/ecommerce'

export const trackAddShippingInfo = (cart: Cart | null) => {
  if (!cart) return
  const ecommerceEventData = createEcommEventDataFromCart(cart)

  const withFulfillment = {
    ...ecommerceEventData,
    fulfillment_type: cart.fulfillment_type?.toUpperCase() as
      | FulfillmentType
      | undefined
  }

  slerpGA4EcommTrackAddShippingInfo(withFulfillment)
  merchantGA4EcommTrackAddShippingInfo(withFulfillment)
}

// CheckoutV2 tracking:
export const trackConsumerCart = (
  cart: ConsumerCart,
  merchantName: string,
  storeName: string
) => {
  const cartParams = cart.orderItems.map((item) => {
    const { name, variantPrice, quantity } = item
    const trackParams = {
      name,
      currency: 'gbp',
      price: variantPrice.discounted ?? variantPrice.base,
      quantity
    }
    return trackParams
  })
  const body = {
    category: 'Product',
    action: 'Begin Checkout'
  }

  trackUserActionsGA4(body, 'slerpGA4Tracking')

  // legacy tracking
  trackUserActionsFBPixel('InitiateCheckout', cartParams)
  trackUserActionsGA4(body, 'merchantGA4Tracking')

  // ecommerce tracking
  const ecommerceEventData = createEcommEventDataFromConsumerCart(
    cart,
    merchantName,
    storeName
  )
  slerpGA4EcommTrackBeginCheckout(ecommerceEventData)
  merchantGA4EcommTrackBeginCheckout(ecommerceEventData)
}

export const trackAddShippingInfoConsumerCart = (
  cart: ConsumerCart,
  merchantName: string,
  storeName: string
) => {
  const ecommerceEventData = createEcommEventDataFromConsumerCart(
    cart,
    merchantName,
    storeName
  )

  const withFulfillment = {
    ...ecommerceEventData,
    fulfillment_type: cart.fulfillment.type?.toUpperCase() as
      | FulfillmentType
      | undefined
  }

  slerpGA4EcommTrackAddShippingInfoConsumerCart(withFulfillment)
  merchantGA4EcommTrackAddShippingInfoConsumerCart(withFulfillment)
}

export const trackAddDiscountCodeConsumerCart = (discountCode: string) => {
  const trackable = {
    content_name: discountCode,
    content_type: 'product'
  }
  const body = {
    category: 'Checkout',
    action: TrackableEvent.CouponApplied,
    label: discountCode
  }

  trackUserActionsGA4(body, 'slerpGA4Tracking')

  // legacy tracking
  trackUserActionsFBPixel(TrackableEvent.CouponApplied, trackable)
  trackUserActionsGA4(body, 'merchantGA4Tracking')
}

export const trackDeniedDiscountCodeConsumerCart = (discountCode: string) => {
  const trackable = {
    content_name: discountCode,
    content_type: 'product'
  }
  const body = {
    category: 'Checkout',
    action: TrackableEvent.CouponDenied,
    label: discountCode
  }

  trackUserActionsGA4(body, 'slerpGA4Tracking')

  // legacy tracking
  trackUserActionsFBPixel(TrackableEvent.CouponDenied, trackable)
  trackUserActionsGA4(body, 'merchantGA4Tracking')
}

export const trackRemoveDiscountCodeConsumerCart = (discountCode: string) => {
  const trackable = {
    content_name: discountCode,
    content_type: 'checkout'
  }
  const body = {
    category: 'Checkout',
    action: TrackableEvent.CouponRemoved,
    label: discountCode
  }

  trackUserActionsGA4(body, 'slerpGA4Tracking')

  // legacy tracking
  trackUserActionsFBPixel(TrackableEvent.CouponRemoved, trackable)
  trackUserActionsGA4(body, 'merchantGA4Tracking')
}

export const trackMovActioned = () => {
  const event = TrackableEvent.MOVActioned
  trackAction(
    {
      category: 'Checkout',
      action: 'Clicked continue shopping'
    },
    { event }
  )
}
