import styled from '@emotion/styled'
import { Discount as DiscountType, LoyaltyCard, Summary } from 'shop/types/cart'
import { CartBreakdownItem, Discount } from '../index'
import { FeeBreakdown } from './index'
import { Rewards } from '../Loyalty'
import { useShop } from 'shop/hooks'
import {
  getApplicableRewards,
  getFulfillmentFlags
} from 'shop/components/Checkout'
import { FulfillmentType } from 'shop/types'

interface Props {
  loyaltyCards?: LoyaltyCard[]
  summary: Summary
  fulfillmentType: FulfillmentType
  isEditable?: boolean
  isCartLoading?: boolean
}

const CartBreakdown = ({
  summary,
  loyaltyCards,
  fulfillmentType,
  isEditable = false,
  isCartLoading = false
}: Props) => {
  const { merchant } = useShop()
  const { discount, reward, storeFee, delivery, tip, subtotal, total } = summary

  const { isDelivery } = getFulfillmentFlags(fulfillmentType)

  if (!merchant) return <></>

  const showDiscount = isEditable || !!discount

  const applicableRewards = getApplicableRewards(loyaltyCards)
  const showRewards = isEditable && !!applicableRewards?.length

  const getAppliedDiscount = (): DiscountType | null => {
    if (!reward?.id && !discount?.code) return null
    return {
      code: reward?.name || discount?.code || '',
      description: reward?.description || discount?.description || '',
      amount: Number(reward?.amount || discount?.amount)
    }
  }

  return (
    <BreakdownSection data-testid='cart-breakdown'>
      {showRewards && (
        <Rewards rewards={applicableRewards} appliedReward={reward}></Rewards>
      )}
      {showDiscount && (
        <Discount
          isEditable={isEditable}
          discount={getAppliedDiscount()}
          hasRewards={showRewards}
        />
      )}
      <CartBreakdownItem
        fontWeight={500}
        label={'Subtotal'}
        price={subtotal}
        isCartLoading={isCartLoading}
        lineBreakHeight={'2px'}
      />
      <FeeBreakdown
        storeFee={storeFee?.price}
        isDelivery={isDelivery}
        deliveryFee={delivery}
        tip={tip}
        isCartLoading={isCartLoading}
      />
      <CartBreakdownItem
        fontWeight={600}
        fontSize={20}
        label={'Total'}
        price={total}
        lineBreakHeight={'2px'}
        isCartLoading={isCartLoading}
      />
    </BreakdownSection>
  )
}

const BreakdownSection = styled.div(() => ({
  '> div:last-of-type > div': {
    paddingBottom: '0'
  }
}))

export default CartBreakdown
