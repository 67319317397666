import React, { useState } from 'react'
import CenterModal from 'shop/components/Modal/CenterModal'
import CustomerBirthday from './CustomerBirthday'
import CustomerOptIn from './../Account/CustomerOptIn'
import styled from '@emotion/styled'
import Spinner from 'shop/components/Loader/Spinner'
import { Button, focusNextInput } from 'shop/components'
import { MUTATE_EDIT_CUSTOMER } from 'shop/client/queries/CustomerQueries'
import { useShop } from 'shop/hooks'
import { isValidBirthdate, customerClient, containsPlusInString } from './utils'
import { slideUp } from '../Shop/commonStyles'
import parsePhoneNumberFromString, { PhoneNumber } from 'libphonenumber-js'
import { IntlPhoneNumberV2 } from '../Inputs'
import { useForm } from 'react-hook-form'
import {
  updateContactNum,
  handlePhoneNumberOnChange,
  handlePhoneNumberOnBlur
} from 'shop/components'
import { strippedContactNumPrefix } from 'shop/components/Inputs/utils'
import FormInput from '../Inputs/FormInput'
import { emailRegex } from 'shop/utils/common'
import useInputKeyEnterHandler from 'shop/hooks/useInputKeyEnterHandler'

export enum EditIds {
  firstName = 'first_name',
  lastName = 'last_name',
  email = 'email',
  contactNum = 'contact_num',
  contactNumPrefix = 'contact_num_prefix',
  day = 'day',
  month = 'month',
  year = 'year'
}

type UpdateInfoProps = {
  updatedFirstName: string
  updatedLastName: string
  updatedEmail: string
  updatedContactNum: string
  updatedBirthdate: string
  updatedBirthdayEditCount: number
  updatedOptIn: boolean
}

interface Props {
  onClose: () => void
  first_name: string
  last_name: string
  email: string
  merchant_id: string
  customerId: string
  contact_num: string
  birthdate: string
  birthday_update_count: number
  marketing_opt_in: boolean
  updateInfo: (arg0: UpdateInfoProps) => void
}

const CustomerEditForm = ({
  first_name,
  last_name,
  email,
  merchant_id,
  customerId,
  contact_num,
  onClose,
  birthdate,
  birthday_update_count,
  marketing_opt_in,
  updateInfo
}: Props) => {
  const { customerApiKey, setCustomerDetails, merchant } = useShop()

  const [isLoading, setIsLoading] = useState(false)
  const [showSaveError, setShowSaveError] = useState(false)
  const [emailInvalid, setEmailInvalid] = useState(false)
  const [isOptedIn, setIsOptedIn] = useState(marketing_opt_in)

  const savedContactNum: PhoneNumber | undefined = contact_num
    ? parsePhoneNumberFromString(contact_num)
    : undefined
  const contactNumPrefix: string = savedContactNum?.isValid()
    ? `+${savedContactNum.countryCallingCode}`
    : '+44'
  const contactNum = savedContactNum?.isValid()
    ? savedContactNum.nationalNumber
    : ''
  const { contact_num_prefix, contact_num: contact_num_updated } =
    updateContactNum(contactNum, contactNumPrefix)

  const [defaultYear, defaultMonth, defaultDay] = !!birthdate
    ? birthdate.split('-')
    : ['', '', '']

  const form = useForm<{ [key: string]: string }>({
    mode: 'onBlur',
    defaultValues: {
      [EditIds.contactNumPrefix]: contact_num_prefix,
      [EditIds.contactNum]: contact_num_updated,
      [EditIds.firstName]: first_name,
      [EditIds.lastName]: last_name,
      [EditIds.email]: email,
      [EditIds.day]: defaultDay || '',
      [EditIds.month]: defaultMonth || '',
      [EditIds.year]: defaultYear || ''
    }
  })

  const { errors, setValue, trigger, register, watch, setError } = form
  const client = customerClient(customerApiKey, merchant?.id || '')

  const handleSubmit = async (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    setIsLoading(true)

    const values = form.getValues()
    const isValid = await trigger()

    const hasBirthdayValues =
      !!values[EditIds.year] || !!values[EditIds.month] || !!values[EditIds.day]
    const customerBirthdate = hasBirthdayValues
      ? `${values[EditIds.year]}-${values[EditIds.month]}-${values[EditIds.day]}`
      : ''
    const isValidDate = isValidBirthdate(customerBirthdate)

    if (!isValidDate) {
      const message = 'Please enter a valid date'

      setError('day', { type: 'custom', message })
      setError('month', { type: 'custom', message })
      setError('year', { type: 'custom', message })
      setIsLoading(false)
      return
    }

    if (!isValid) {
      setIsLoading(false)
      return
    }
    client
      .mutate({
        mutation: MUTATE_EDIT_CUSTOMER,
        variables: {
          firstName: values[EditIds.firstName],
          lastName: values[EditIds.lastName],
          customerId,
          email: values[EditIds.email],
          contactNum: `${strippedContactNumPrefix(values[EditIds.contactNumPrefix])}${values[EditIds.contactNum]}`,
          merchantId: merchant_id,
          birthdate: customerBirthdate.length > 0 ? customerBirthdate : null,
          marketingOptIn: isOptedIn
        }
      })
      .then((results) => {
        const {
          first_name,
          last_name,
          email,
          contact_num,
          birthdate,
          birthday_update_count,
          marketing_opt_in
        } = results.data.editCustomer
        localStorage.setItem('customerName', `${first_name} ${last_name}`)
        // update global context of customer
        setCustomerDetails({
          firstName: first_name,
          lastName: last_name,
          email,
          phoneNumber: contact_num,
          marketingOptIn: marketing_opt_in
        })
        updateInfo({
          updatedFirstName: first_name,
          updatedLastName: last_name,
          updatedEmail: email,
          updatedContactNum: contact_num,
          updatedBirthdate: birthdate,
          updatedBirthdayEditCount: birthday_update_count,
          updatedOptIn: marketing_opt_in
        })
        onClose()
      })
      .catch((error) => {
        setIsLoading(false)
        switch (error.message) {
          case 'GraphQL error: Please use another email address, as this one is taken already.':
            setEmailInvalid(true)
            break

          default:
            setShowSaveError(true)
        }
      })
  }

  const handlePhoneInputsBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handlePhoneNumberOnBlur(e, setValue, trigger, 'contact_num')
  }

  const inputIdList = [
    `${EditIds.firstName}-input`,
    `${EditIds.lastName}-input`,
    `${EditIds.email}-input`,
    `${EditIds.contactNum}`
  ]

  useInputKeyEnterHandler({
    handleKeyPress: () => focusNextInput(inputIdList)
  })

  const num_prefix = watch(EditIds.contactNumPrefix)
  const national_num = watch(EditIds.contactNum)
  const disableDateFields = birthday_update_count >= 3

  return (
    <CenterModal handleCloseModal={onClose}>
      <Form onSubmit={handleSubmit} data-testid='customerEditModal'>
        <Header>Edit Personal Info</Header>
        <CustomerFields>
          <Row>
            <RowField>
              <FormInput
                id={EditIds.firstName}
                name={EditIds.firstName}
                labelText='First name *'
                formRef={register({
                  required: 'Please enter your first name',
                  validate: (value: string) =>
                    value.trim().length > 0
                      ? true
                      : 'Please enter your first name'
                })}
                value={first_name || ''}
                showError={!!errors[EditIds.firstName]}
                onBlur={() => trigger(EditIds.firstName)}
              />
            </RowField>
            <RowDivider />
            <RowField>
              <FormInput
                id={EditIds.lastName}
                name={EditIds.lastName}
                labelText='Last name *'
                formRef={register({
                  required: 'Please enter your last name',
                  validate: (value: string) =>
                    value.trim().length > 0
                      ? true
                      : 'Please enter your last name'
                })}
                value={last_name || ''}
                showError={!!errors[EditIds.lastName]}
                onBlur={() => trigger(EditIds.lastName)}
              />
            </RowField>
          </Row>
          <Row>
            <FormInput
              id={EditIds.email}
              name={EditIds.email}
              labelText='Email address *'
              formRef={register({
                required: 'Please enter your email address',
                pattern: emailRegex,
                validate: (value) =>
                  containsPlusInString(value)
                    ? 'Please remove “+” character'
                    : true
              })}
              value={email || ''}
              showError={!!errors[EditIds.email]}
              onBlur={() => trigger(EditIds.email)}
            />
            {containsPlusInString(email) && (
              <ErrorMessage data-testid='errorMessage'>
                ”Please remove “+” character”
              </ErrorMessage>
            )}
          </Row>
          <Row>
            <IntlPhoneNumberV2
              labelText='Phone Number'
              onNumberChange={handlePhoneNumberOnChange}
              onBlur={(e) => {
                handlePhoneInputsBlur(e)
                trigger(EditIds.contactNum)
              }}
              formHandle={form}
              prefixDropdownValue={num_prefix}
              prefixDropdownId={EditIds.contactNumPrefix}
              prefixDropdownName={EditIds.contactNumPrefix}
              phoneNumberInputName={EditIds.contactNum}
              phoneNumberInputId={EditIds.contactNum}
              phoneNumberValue={national_num || contact_num_updated || ''}
              showError={
                !!errors[EditIds.contactNum] ||
                !!errors[EditIds.contactNumPrefix]
              }
            />
          </Row>
          <CustomerBirthday
            disableDateFields={disableDateFields}
            updateCount={birthday_update_count}
            formHandler={form}
          />
          <CustomerOptIn
            isOptedIn={isOptedIn}
            onChange={() => setIsOptedIn(!isOptedIn)}
          />
          {!!Object.keys(errors).length && (
            <ErrorMessage>{Object.values(errors)?.[0]?.message}</ErrorMessage>
          )}
          {showSaveError && (
            <ErrorMessage data-testid='errorMessage'>
              There's an error on saving.
            </ErrorMessage>
          )}
          {emailInvalid && (
            <ErrorMessage data-testid='errorMessage'>
              Please use another email address, as this one is taken already.
            </ErrorMessage>
          )}
        </CustomerFields>
        <Button type='submit' disabled={isLoading}>
          {isLoading && <Spinner />}
          Save Changes
        </Button>
      </Form>
    </CenterModal>
  )
}

export const Form = styled.form(({ theme }: any) => ({
  animation: `${slideUp} 100ms ease-out`,
  position: 'relative',
  borderRadius: '4px',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  zIndex: theme.zIndex.accountModal,
  fontFamily: theme.fonts.body.family,
  margin: '16px auto',
  maxWidth: '480px',
  width: '100%',
  padding: '24px',
  '> h2': {
    textAlign: 'center',
    lineHeight: 1,
    marginBottom: '24px',
    fontWeight: theme.fontWeights.bold
  },
  [theme.mediaQueries.viewport7]: {
    width: '100%',
    height: 'auto',
    padding: '48px 36px 16px',
    overflowY: 'hidden',
    marginBottom: '32px',
    '> h2': {
      lineHeight: 0,
      fontWeight: theme.fontWeights.bold,
      padding: '0 72px'
    }
  }
}))

const Row = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  marginBottom: '16px'
}))

const RowField = styled.div((props: any) => ({
  width: props.width ? props.width : '50%',
  display: 'flex',
  alignItems: 'flex-end'
}))

const RowDivider = styled.div(() => ({
  margin: '0 8px'
}))

const CustomerFields = styled.div(() => ({
  padding: '16px 0',
  justifyContent: 'center'
}))

const ErrorMessage = styled.p(({ theme }: any) => ({
  color: theme.colors.state.error[5],
  fontSize: theme.fontSizes[1],
  fontWeight: theme.fontWeights.normal
}))

const Header = styled.h2(({ theme }: any) => ({
  fontSize: theme.fontSizes[3],
  fontFamily: theme.fonts.body.family,
  fontWeight: theme.fontWeights.bold
}))

export default CustomerEditForm
