import React, { useState } from 'react'
import format from 'date-fns/format'
import { createDate } from 'shop/utils/date'
import styled from '@emotion/styled'
import { isTableOrder } from 'shop/components/Cart/utils'
import { useModal } from 'shop/hooks'
import { redirectUrlV2 } from '../Checkout'

interface Order {
  transaction_id: string
  inserted_at: Date
  total: string
  status: string
  cart_id: string
  store_id: string
  store: {
    slug: string
  }
  metadata: {
    pickup_type?: string
  }
  cart: {
    address: string
  }
  fulfillment_type: string
}

interface Props {
  orders: Order[]
  reorderingEnabled?: boolean
}

interface RowProps {
  order: Order
  handleModalOpen: (arg0: Order) => void
  reorderingEnabled?: boolean
}

interface FulfillmentProps {
  storeId: string
  storeSlug: string
  isDineIn: boolean
  cartId: string
  orderAgainCartId: string
}

const OrderTable = ({ orders, reorderingEnabled }: Props) => {
  const areOrdersEmpty = orders.length === 0
  const [fulfillmentProps, setFulfillmentProps] = useState<FulfillmentProps>({
    storeSlug: '',
    storeId: '',
    cartId: '',
    isDineIn: false,
    orderAgainCartId: ''
  })
  const { isModalOpen, openModal, fulfillmentModal } = useModal()

  const handleModalClose = () => {
    setFulfillmentProps({
      storeSlug: '',
      storeId: '',
      cartId: '',
      isDineIn: false,
      orderAgainCartId: ''
    })
  }

  const handleModalOpen = (order: Order) => {
    const { cart_id, store_id, store, metadata } = order
    openModal('fulfillment')
    setFulfillmentProps({
      storeSlug: store.slug,
      storeId: store_id,
      cartId: cart_id,
      isDineIn: isTableOrder(metadata),
      orderAgainCartId: cart_id
    })
  }

  return (
    <TableContainer>
      {isModalOpen('fulfillment') &&
        fulfillmentModal({
          ...fulfillmentProps,
          onClose: handleModalClose
        })}
      <h3>Recent Orders</h3>
      {areOrdersEmpty && (
        <p data-testid='emptyOrders'>You don't have any orders yet</p>
      )}
      {!areOrdersEmpty && (
        <Table data-testid='orderTable'>
          <tbody>
            <OrderTableHeaders />
            {orders.map((order: Order) => (
              <OrderTableRows
                key={order.transaction_id}
                {...{ order, handleModalOpen, reorderingEnabled }}
              />
            ))}
          </tbody>
        </Table>
      )}
    </TableContainer>
  )
}

const OrderTableHeaders = () => {
  return (
    <tr>
      <TableHeader align='left'>Transaction ID</TableHeader>
      <TableHeader align='left'>Date ordered</TableHeader>
      <TableHeader align='right'>Order total</TableHeader>
      <TableHeader align='left'>Status</TableHeader>
      <TableHeader align='right' />
      <TableHeader align='right' />
    </tr>
  )
}

const OrderTableRows = ({
  order,
  handleModalOpen,
  reorderingEnabled
}: RowProps) => {
  const { inserted_at, total, transaction_id } = order
  const parsedDate = createDate(inserted_at)
  const orderLink = redirectUrlV2(transaction_id)

  return (
    <tr>
      <TableData align='left'>{transaction_id}</TableData>
      <TableData align='left'>{format(parsedDate, 'dd/MM/yyyy')}</TableData>
      <TableData align='right'>£{parseFloat(total).toFixed(2)}</TableData>
      <TableData align='left'>
        <Status>{order.status}</Status>
      </TableData>
      <TableData>
        <Link target='_blank' href={orderLink}>
          View
        </Link>
      </TableData>
      {reorderingEnabled && (
        <TableData>
          <Link
            href='#'
            data-testid='orderAgainLink'
            onClick={() => handleModalOpen(order)}
          >
            Order again
          </Link>
        </TableData>
      )}
    </tr>
  )
}

const TableContainer = styled.div(({ theme }: any) => ({
  marginBottom: '24px',
  minHeight: '25vh',
  '> h3': {
    fontWeight: theme.fontWeights.bold
  }
}))

const Table = styled.table(({ theme }: any) => ({
  width: '100%',
  maxWidth: '960px',
  borderCollapse: 'collapse',
  margin: '12px 0',
  '> * > tr:nth-of-type(even)': {
    backgroundColor: '#f7f7f7'
  }
}))

const TableHeader = styled.th(({ theme, align }: any) => ({
  textAlign: align,
  padding: '4px',
  paddingRight: align === 'right' ? '48px' : '0',
  fontWeight: theme.fontWeights.bold,
  fontSize: theme.fontSizes[1]
}))

const TableData = styled.td(({ theme, align }: any) => ({
  textAlign: align,
  padding: '8px',
  paddingRight: align === 'right' ? '48px' : '0',
  fontSize: theme.fontSizes[1]
}))

const Status = styled.p(() => ({
  textTransform: 'capitalize',
  margin: '0'
}))

const Link = styled.a(({ theme }: any) => ({
  fontSize: theme.fontSizes[2],
  color: theme.colors.textMute,
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  '& > span': {
    margin: '0 8px'
  }
}))

export default OrderTable
