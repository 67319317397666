import {
  AppliedModifier,
  Cart,
  CartSession,
  CartItem,
  AdditionalStoreFee
} from 'shop/components/Landing/types'
import { Decimal } from 'decimal.js'
import { ISOtoUTC } from './../NavBar/utils'
import { isBefore, isPast, parseISO } from 'date-fns'
import { isNull } from 'lodash'
import { MUTATE_UPDATE_CUSTOMER_ID } from 'shop/client'
import { ApolloClient } from '@apollo/client'
import { Price } from 'shop/types/cart'
import { DeliveryReduction } from '../Checkout/types'

type StoreVariant = {
  variant_id: string
  in_stock: boolean
  published_at?: string
  stock_count?: number
  stock_sold?: number
  stock_type?: string
}

export type Metadata = {
  pickup_type?: string
  recipient?: string
}

export const filterAlcoholicProducts = (cartItems: CartItem[]) => {
  return cartItems.filter((item: CartItem) => {
    if (
      item.product_variant.restrictions.alcoholic ||
      hasAlcoholicModifier(item.applied_modifiers)
    )
      return item
    return null
  })
}

export const getDeviceType = (isMobile: boolean, isDesktop: boolean) => {
  if (isMobile) {
    return 'mobile'
  }
  if (isDesktop) {
    return 'desktop'
  }
  return null
}

export const computeModifiersTotal = (modifiers: AppliedModifier[]) => {
  const total = modifiers.reduce((acc: Decimal, modifier: AppliedModifier) => {
    return acc.plus(new Decimal(modifier.amount))
  }, new Decimal(0))

  return total.toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber()
}

const normalizeNegative = (value: Decimal) => {
  return value.lessThan(new Decimal(0)) ? new Decimal(0) : value
}

export const computeCartSubtotal = (
  cartItems: CartItem[],
  giftWrapPrice = 0,
  discountAmount = 0
) => {
  const itemsTotal = computeItemsTotal(cartItems)
  const itemDiscounts = new Decimal(discountAmount)

  return normalizeNegative(itemsTotal.minus(itemDiscounts))
    .plus(new Decimal(giftWrapPrice))
    .toDecimalPlaces(2, Decimal.ROUND_HALF_UP)
    .toNumber()
}

export const computeCartTotal = (
  cartItems: CartItem[],
  giftWrapPrice = 0,
  discountAmount = 0,
  deliveryReduction: DeliveryReduction | null = null,
  discountTarget: string | null = null,
  additionalStoreFeeAmount = 0
): number => {
  const itemsTotal = computeItemsTotal(cartItems)
  const base = calculateBase(
    discountTarget,
    new Decimal(discountAmount),
    deliveryReduction,
    new Decimal(additionalStoreFeeAmount),
    itemsTotal
  )

  // revisions needed for different discount targets
  return base
    .plus(new Decimal(giftWrapPrice))
    .toDecimalPlaces(2, Decimal.ROUND_HALF_UP)
    .toNumber()
}

const calculateBase = (
  discountTarget: string | null,
  discount: Decimal,
  deliveryReduction: DeliveryReduction | null,
  additionalStoreFee: Decimal,
  itemsTotal: Decimal
) => {
  switch (discountTarget) {
    case 'delivery_fee': {
      const deliveryCharge = new Decimal(
        deliveryReduction?.deliveryChargeBeforeDiscount || 0
      )
      return itemsTotal
        .plus(normalizeNegative(deliveryCharge.minus(discount)))
        .plus(additionalStoreFee)
    }
    case 'all_charges': {
      const deliveryCharge = new Decimal(
        deliveryReduction?.deliveryChargeBeforeDiscount || 0
      )
      return normalizeNegative(
        itemsTotal.plus(deliveryCharge).plus(additionalStoreFee).minus(discount)
      )
    }
    case 'subtotal_delivery_fee': {
      const deliveryCharge = new Decimal(
        deliveryReduction?.deliveryChargeBeforeDiscount || 0
      )
      return normalizeNegative(
        itemsTotal.plus(deliveryCharge).minus(discount)
      ).plus(additionalStoreFee)
    }
    default: {
      const deliveryCharge = new Decimal(deliveryReduction?.deliveryCharge || 0)
      return normalizeNegative(itemsTotal.minus(discount))
        .plus(deliveryCharge)
        .plus(additionalStoreFee)
    }
  }
}

export const computeItemsTotal = (cartItems: CartItem[]) => {
  return cartItems.reduce((acc: Decimal, cartItem: CartItem) => {
    return acc.plus(new Decimal(cartItem.amount))
  }, new Decimal(0))
}

export const computeAdditionalStoreFee = (
  cart: Cart,
  storeFee?: AdditionalStoreFee,
  additionalStoreFeeDiscount?: Price | null
): {
  base: number
  discounted: number
  reduction: number
} => {
  if (!!additionalStoreFeeDiscount?.base) {
    return {
      base: sanitizeNumber(additionalStoreFeeDiscount.base),
      discounted: sanitizeNumber(additionalStoreFeeDiscount.discounted),
      reduction: sanitizeNumber(additionalStoreFeeDiscount.reduction)
    }
  }

  if (storeFee && storeFee.is_active && isStoreFeeForCart(storeFee, cart)) {
    return storeFeeToPrice(cart, storeFee)
  }

  return {
    base: 0,
    discounted: 0,
    reduction: 0
  }
}

const storeFeeToPrice = (cart: Cart, storeFee: AdditionalStoreFee) => {
  if (storeFee.amount_type === 'percentage') {
    const storeFeeAmount = new Decimal(storeFee.amount || 0).dividedBy(100)
    const storeFeeTotal = new Decimal(cart.subtotal).times(storeFeeAmount)

    return {
      base: storeFeeTotal.toNumber(),
      discounted: storeFeeTotal.toNumber(),
      reduction: 0
    }
  }

  return {
    base: sanitizeNumber(storeFee.amount),
    discounted: sanitizeNumber(storeFee.amount),
    reduction: 0
  }
}

export const isStoreFeeForCart = (
  storeFee: AdditionalStoreFee,
  cart: Cart
): boolean => {
  const { fulfillment_type, metadata } = cart
  const { fulfillment_types } = storeFee

  const cartFulfillmentType =
    fulfillment_type === 'pickup' && isTableOrder(metadata)
      ? 'table_ordering'
      : fulfillment_type

  return fulfillment_types ? fulfillment_types[cartFulfillmentType] : false
}

export const isCartDateExpired = (cartSession: CartSession) =>
  cartSession.cart &&
  cartSession.cart.deliver_by &&
  isBefore(ISOtoUTC(cartSession.cart.deliver_by.toString()), new Date())

export const isStoreSwitched = (slug: string, cart: Cart | null) => {
  if (cart) return cart.store.slug !== slug

  return false
}

const hasAlcoholicModifier = (modifiers: AppliedModifier[]) => {
  const alcoholicModifiers = modifiers.find(
    (item: any) => item.modifier.restrictions.alcoholic === true
  )

  return alcoholicModifiers ? true : false
}

// should be a check if item is overbought
export const isItemAvailable = (
  storeVariant?: StoreVariant,
  quantityBought?: number
) => {
  if (!storeVariant) return false

  const bought = quantityBought || 0
  const stockSold = storeVariant.stock_sold || 0
  const stockCount = storeVariant.stock_count || 0
  const stockAvailable = Number(stockCount) - Number(stockSold)

  if (isNull(storeVariant.stock_type))
    return (
      storeVariant &&
      storeVariant.in_stock &&
      storeVariant.published_at !== null
    )

  return bought <= stockAvailable
}

export const checkMinimumOrderValue = (
  cartTotal: number,
  minimumOrderValue?: number | string
) => {
  if (!minimumOrderValue) return true

  return cartTotal >= sanitizeNumber(minimumOrderValue)
}

export const formatMinimumOrderValue = (
  minimumOrderValue?: string | number
) => {
  if (!minimumOrderValue) return ''

  return sanitizeNumber(minimumOrderValue).toFixed(2)
}

export const formatMoney = (price: number) =>
  price.toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP'
  })

export const sanitizeNumber = (value?: string | number | null) => {
  if (!value) return Number(0)
  if (typeof value === 'string') return parseFloat(value)
  return value
}

export const isTableOrder = (metadata?: Metadata) => {
  if (!metadata) return false

  return metadata?.pickup_type === 'table'
}

export const getTableNumber = (metadata?: Metadata) => {
  if (!metadata) return ''

  return metadata && metadata.recipient ? metadata.recipient : ''
}

export const calculateSubtotalWithAnyDiscounts = (cart: Cart) => {
  // ! CARE ! - discount_amount is a string from the BE
  const { discount_amount, discount_target, subtotal } = cart

  // delivery discounts are already calculated part of the BE
  let productDiscount = 0
  if (discount_target !== 'delivery_fee') {
    productDiscount = +discount_amount
  }

  return {
    subtotalWithAnyDiscounts: subtotal - productDiscount
  }
}

export const getCurrentCartId = (domain: string) => {
  const cartDetails = localStorage.getItem(domain)

  if (!cartDetails) {
    return null
  }

  try {
    const parsedDetails = JSON.parse(cartDetails)

    const expiresAt = parsedDetails['expires_at']
    if (expiresAt && isPast(parseISO(expiresAt))) {
      localStorage.removeItem(domain)
      return null
    }

    return parsedDetails.cart_id || null
  } catch {
    localStorage.removeItem(domain)
    return null
  }
}

/** Checks whether user is logged in via hasura API */
export const isUserLoggedInViaHasura = (loggedIn: boolean): boolean => {
  // authVersion only exists if user logged in via consumer API
  return loggedIn && !localStorage.getItem('authVersion')
}

export const updateCartWithCustomerId = (
  client: ApolloClient<object>,
  customerId: string,
  cartId: string
) => {
  return client.mutate({
    mutation: MUTATE_UPDATE_CUSTOMER_ID,
    variables: {
      cartId,
      customerId
    },
    fetchPolicy: 'no-cache'
  })
}

/** Gets min order value from hasura getCartById cart type */
export const getCartMinOrderValue = (
  isPreOrder: boolean,
  cart: Cart
): number => {
  const deliveryMOV = isPreOrder
    ? cart.store.preorder_delivery_minimum_order_value || 0
    : cart.store.sameday_delivery_minimum_order_value || 0
  const pickupMOV = isPreOrder
    ? cart.store.preorder_pickup_minimum_order_value || 0
    : cart.store.sameday_pickup_minimum_order_value || 0

  return cart.fulfillment_type === 'delivery' ? deliveryMOV : pickupMOV
}
